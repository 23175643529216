<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <ValidationProvider name="Warehouse Type (En)" vid="warehouse_type_name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="5"
                        label-for="warehouse_type_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('warehouse_config.warehouse_type_namef')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="warehouse_type_name"
                          v-model="warehouseType.warehouse_type_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="Warehouse Type (Bn)" vid="warehouse_type_name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="5"
                        label-for="warehouse_type_name_bn"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('warehouse_config.warehouse_type_name_bn')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="warehouse_type_name_bn"
                          v-model="warehouseType.warehouse_type_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>

                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { warehouseTypeStore, warehouseTypeUpdate } from '../../api/routes'
import { Common } from '@/mixins/helper-functions'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'

export default {
  name: 'FormLayout',
  mixins: [Common],
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    warehouseTypeList: function () {
       return this.$store.state.list.find(item => item.id === this.id)
    }
  },
  created () {
    if (this.id) {
      const data = this.getWarehouseTypeData()
      this.warehouseType = data
    }
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      warehouseType: {
        id: '',
        warehouse_type_name: '',
        warehouse_type_name_bn: ''
      }
    }
  },
  methods: {
    getWarehouseTypeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      let result = null
      const loadinState = { loading: false, listReload: true }

      if (this.warehouseType.id) {
        result = await RestApi.putData(warehouseServiceBaseUrl, `${warehouseTypeUpdate}/${this.id}`, this.warehouseType)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, warehouseTypeStore, this.warehouseType)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data Update successfully' : 'Data saved successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
