<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-cols-md="5"
                            label-cols-lg="5"
                            :label="$t('warehouse_config.warehouse_type_name')"
                            label-for="warehouse_type_name"
                        >
                        <v-select name="warehouseTypeID"
                        v-model="search.warehouseTypeID"
                        label="text"
                        :options= wareTypeList
                        :reduce="obj => obj.value"
                        :placeholder="$t('globalTrans.select')"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('warehouse_config.warehouse_type') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(warehouse_type_name)="data">
                                            <span class="capitalize">{{ data.item.warehouse_type_name }}</span>
                                        </template>
                                        <template v-slot:cell(warehouse_type_name_bn)="data">
                                            {{ data.item.warehouse_type_name_bn }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                                            <b-badge variant="danger" v-if="data.item.status == 1">{{$t('globalTrans.inactive')}}</b-badge>
                                            <b-badge variant="success" v-else>{{$t('globalTrans.active')}}</b-badge>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" -->
                                                    <!-- @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button> -->
                                            <!-- <b-button variant=" iq-bg-danger" size="sm" -->
                                                    <!-- @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-off"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Status" size="sm" @click="remove(data.item)" v-else>
                                                <i class="fa fa-toggle-on"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-if="pagination.totalRows"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
           <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import Form from './Form'
import { warehouseTypeList, warehouseTypeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                warehouseTypeID: null
            }
        }
    },
    computed: {
        wareTypeList: function () {
          const tmpList = this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
          return tmpList.map(item => ({ text: item.text, value: item.value }))
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('warehouse_config.warehouse_type_entry') : this.$t('warehouse_config.warehouse_type_modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('warehouse_config.warehouse_type'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'warehouse_type_name' },
                { key: 'status' },
                { key: 'action' }
            ]

            if (this.$i18n.locale === 'bn') {
                keys[1] = { key: 'warehouse_type_name_bn' }
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        await this.loadData()
    },
    methods: {
        async searchData () {
            await this.loadData()
        },
        remove (item) {
            this.changeStatus(warehouseServiceBaseUrl, warehouseTypeToggleStatus, item, 'warehosue', 'warehouseTypeList')
        },
        loadData () {
            var wareTypeIdSerach = ''
            if (this.search.warehouseTypeID) {
                wareTypeIdSerach = this.search.warehouseTypeID
            }
            const params = Object.assign({}, this.search, { wareTypeID: wareTypeIdSerach, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(warehouseServiceBaseUrl, warehouseTypeList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
